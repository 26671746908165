<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3>Asignación de Tags</h3>
        <v-select
          v-model="client"
          :items="clients"
          label="Selecciona Cliente"
          dense
          solo
          required
          class="mt-3"
          item-text="clientDesc"
          return-object
        >
        </v-select>
        <v-text-field
          v-model="tags"
          label="Tags, formato: n-m, ejemplo: 3-8"
          required
          ></v-text-field>
        <v-btn 
          color="primary" 
          @click="assignTags"
          class="mt-3"
          :disabled="client === null || tags === null"
        >
          Asignar
        </v-btn>  
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '../router/index'
import {db} from '../firebase'
import { doc, setDoc } from "firebase/firestore";
import { mapState, mapActions } from 'vuex'

export default {
  name: 'TagsAssignation',

  data() {
    return {
      client: null,
      tags: null
    }
  },

  computed: {
    ...mapState(['clients']),
  },

  methods: {
    ...mapActions(['logOut']),

    assignTags(){
      //console.log(this.client.id)
      //console.log(this.client.desc)
      const myArray = this.tags.split("-")
      //console.log(myArray)
      const tagStart = parseInt(myArray[0])
      const tagEnd = parseInt(myArray[1])
      for (let i = tagStart ; i <= tagEnd ; i++){
        let tagRef = doc(db, `tags/${this.client.clientId}/list`, `${i}`)
        setDoc(tagRef, {
          tagId: i.toString(),
          clientId: this.client.clientId,
          clientDesc: this.client.clientDesc,
          url: null
        }, { merge: true })
      }
    router.push('/tags')
    //this.logOut()
    }
    
    
  },


}
</script>

<style>

</style>
